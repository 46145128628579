@import url(http://fonts.googleapis.com/css?family=Open%20Sans:300,400,500,600,700);
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap');

.space{
    padding-top: 50px;
}

.details_heading {
    font-family: 'Rajdhani', sans-serif;
    font-family: Segoe UI,system-ui,-apple-system,sans-serif;
    font-family: "Poppins", Arial, sans-serif;
    font-size: 35px;
}

.project_text{
    font-family: 'Rajdhani', sans-serif;
    font-family: Segoe UI,system-ui,-apple-system,sans-serif;
    font-family: "Poppins", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.6;
    font-size: 18px;
    opacity: 0.8;
}

.project_text_head{
    font-family: 'Rajdhani', sans-serif;
    font-family: Segoe UI,system-ui,-apple-system,sans-serif;
    font-family: "Poppins", Arial, sans-serif;
    font-weight: 700;
    line-height: 1.6;
    font-size: 18px;
}

.meet_link_button {
    margin-top: 20px;
    background-color: rgb(6, 6, 121);
}