#responsive-navbar-nav{
    justify-content: flex-end;
}

.navbar{
    background-color: white !important;
}

.navbar-nav{
    margin-right:0px!important;
}

.nav_link{
    font-family: "Poppins", Arial, sans-serif;
    text-decoration: none;
    padding-left: 25px !important;
    font-size: 15px;
    color : gray;
}

.nav_link2{
    font-family: "Poppins", Arial, sans-serif;
    text-decoration: none;
    font-size: 15px;
    color : gray;
}

.nav_link3{
    font-family: "Poppins", Arial, sans-serif;
    text-decoration: none;
    padding-left: 25px !important;
    font-size: 15px;
    color : gray;
}


.nav_dropdown_link{
    font-family: "Poppins", Arial, sans-serif;
    text-decoration: none;
    font-size: 15px;
    color : gray;
}

.nav_link:hover {
    color: rgb(3, 0, 43) !important;
    text-decoration: none;
    transition-duration: 0.8s;
}

.nav_link2:hover {
    color: rgb(3, 0, 43) !important;
    text-decoration: none;
    transition-duration: 0.8s;
}

.nav_dropdown_link:hover {
    color: rgb(3, 0, 43) !important;
    text-decoration: none;
    transition-duration: 0.8s;
}
