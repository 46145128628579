@import url(http://fonts.googleapis.com/css?family=Open%20Sans:300,400,500,600,700);

.proposal_card{
    text-align: center;
}

.proposal_name {
    margin-top: 50px;
    padding: 10px;
    font-size: 22px;
    font-family: 'Rajdhani', sans-serif;
    font-family: "Poppins", Arial, sans-serif;
    color: black;
    text-align: center;
}

.proposal_button {
    text-align: center;
    color: black;
    background-color: white;
    border: 1px solid black;
}

.proposal_img_box {
    text-align: center;
    padding: 10px;
}

.proposal_project_card_img {
    border: 1px solid black;
    border-radius: 10px;
}
