.home_space6{
  margin-top: 175px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgb(240, 240, 240);
  /* background-image: url(../../images/background2.jpeg);
  background-repeat: no-repeat;
  background-size: cover; */
  background-color: rgba(0, 0, 32, 1);
}

.numbers_card{
  padding: 25px;
  /* background-color: rgb(195, 210, 236) !important; */
}

#safety {
  text-align: center;
  margin: 10% 0;
  font-family: 'Rajdhani', sans-serif;
}

#safety h2
{
    padding-left: 20px;
    font-size: 35px;
    color: rgb(2, 30, 53);
    font-weight: bold;
    text-align: left;
    font: 35px  Segoe UI,system-ui,-apple-system,sans-serif;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
}

#safety h3
{
  font-size: 17;
  font-weight: 800;
  line-height: 1.6;
  color: rgb(0,31,56);
  font-family: 'Rajdhani', sans-serif;
}

#safety p
{
  color: rgb(30, 30, 30);
  text-align: left;
  font: 400 17px/1.6  Segoe UI,system-ui,-apple-system,sans-serif;
  font-family: "Poppins", sans-serif;
}

.icon{
  margin-bottom: 12px;
  color: rgb(0, 31, 56);
}
