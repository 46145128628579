@import url(http://fonts.googleapis.com/css?family=Open%20Sans:300,400,500,600,700);
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo&display=swap');

.tab_year{
    font-size: 20px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.project_space{
    padding-top: 100px;
    padding-bottom: 200px;
}

.project_cards_group {
    columns: 3 300px;
    column-gap: 20px;
    margin: 0 auto;
    margin-top: 50px;
}

.project_card {
    margin-bottom: 20px;
    overflow: hidden;
    display: inline-block;
}

.project_box {
    height: 870px;
}

.project_card_heading{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 33px;
    padding-top: 10px;
    padding-bottom: 20px;
    color: rgb(49, 75, 104);
    font-family: 'Arvo', serif;
    font-family: Segoe UI,system-ui,-apple-system,sans-serif;
    font-family: "Poppins", Arial, sans-serif;
}

.projects_card_content {
    padding: 10px;
    height: 500px;
    overflow: hidden;
}

.project_card_description{
    height: 400px;
    font-size: 15px;
    font-family: 'Rajdhani', sans-serif;
    font-family: Segoe UI,system-ui,-apple-system,sans-serif;
    font-family: "Poppins", Arial, sans-serif;
    overflow: hidden;
}


.project_heading{
    text-align: center;
    font-size: 22px;
    font-family: 'Rajdhani', sans-serif;
    font-family: Segoe UI,system-ui,-apple-system,sans-serif;
    font-family: "Poppins", Arial, sans-serif;
}

.img_box {
    text-align: center;
    padding: 10px;
}

.project_card_img {
    border: 1px solid black;
}

.card_space{
    margin-top: 40px;
}

.card_link {
    padding-left: 20px;
    text-decoration: none;
    font-size: 17px;
    font-family: 'Rajdhani', sans-serif;
    font-family: Segoe UI,system-ui,-apple-system,sans-serif;
    font-family: "Poppins", Arial, sans-serif;
}
.card_link:hover{
    text-decoration: none;
}


  
  
