@import url(http://fonts.googleapis.com/css?family=Open%20Sans:300,400,500,600,700);

.hello{
    background-color: rgb(245,245,245);
}

.blah{
    max-width: 1400px; 
    margin: 0 auto;
    background-color: white;
    overflow: hidden;
}

.row {
    justify-content: center;
}

.banner_background {
    display: block;
    position: relative;
    width: 100%;
    background-image: url(../images/background-dark-grid-fade.png);
    -webkit-background-position: top;
    background-position: top;
    background-color: #001020;
    min-height: 650px;
}

.banner {
    background:linear-gradient(to bottom, rgba(0, 0, 32, 0), rgba(0, 0, 32, 1) 600px);
    min-height: 650px;
}

.banner_text_area {
    max-width: 680px;
    width: 100%;
    margin: 0 auto 0 auto;
    padding-top: 200px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.banner_text1 {
    font-family: Segoe UI,system-ui,-apple-system,sans-serif;
    font-family: "Poppins", Arial, sans-serif;
    color: #fff;
    font-weight: bold;
    font-size: 52px;
    letter-spacing: -0.022em;
    line-height: 120%;
    animation: text 3s 1;
}

.banner_text_others {
    font-family: Segoe UI,system-ui,-apple-system,sans-serif;
    font-family: "Poppins", Arial, sans-serif;
    color: #fff;
    font-weight: bold;
    font-size: 65px;
    letter-spacing: -0.022em;
    line-height: 120%;
    animation: text 3s 1;
}

@keyframes text {
    0%{
        opacity: 0;
        margin-bottom: -10px;
    }
    30%{
        opacity: 0.5;
        margin-bottom: -10px;
    }
    85%{
        opacity: 1;
        margin-bottom: -10px;
    }
}    

.banner_text2 {
    font-family: Segoe UI,system-ui,-apple-system,sans-serif;
    font-family: "Poppins", Arial, sans-serif;
    color: #fff;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    margin-top: 10px;
    letter-spacing: -0.011em;
    animation: text2 2s 1;
}

@keyframes text2 {
    0%{
        opacity: 0;
    }
    30%{
        opacity: 0.5;
    }
    85%{
        opacity: 1;
    }
}

.temp {
    font-family: expo-brand-book,system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

.heading {
    border-left: 5px solid rgb(1, 112, 202);
    padding-left: 20px;
    font-size: 35px;
    font-weight: bold;
    color: rgb(2, 30, 53);
    font-weight: bold;
    font-family: Segoe UI,system-ui,-apple-system,sans-serif;
    font-family: "Poppins", Arial, sans-serif;
}

.heading_icon{
    margin-bottom: 5px;
}

.special_heading {
    text-align: center;
    font-size: 35px;
    color: rgb(219, 219, 219);
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: bold;
}

.special_hr{
    border-top: 1px solid rgb(206, 206, 206);
}

.hr{
    border-top: 1px solid rgb(20, 20, 20);
}

.heading_image {
    color: white;
}

.wave{
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(../images/wave.png);
    background-size: 100% 100px;
}

.appu_background {
    display: block;
    position: relative;
    width: 100%;
    background-image: url(../images/background-dark-grid-fade.png);
    -webkit-background-position: top;
    background-position: top;
    background-color: #001020;
    min-height: 650px;
}

.appu_banner {
    background:linear-gradient(to bottom, rgba(0, 0, 32, 0), rgba(0, 0, 32, 1) 600px);
    min-height: 650px;
}

.appu1 {
    max-width: 680px;
    width: 100%;
    margin: 0 auto 0 auto;
    padding-top: 200px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.appu2 {
    font-family: expo-brand-bold,system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    color: #fff;
    font-weight: 500;
    font-size: 55px;
    letter-spacing: -0.022em;
    line-height: 120%;
    animation: text 3s 1;
}

@keyframes text {
    0%{
        opacity: 0;
        margin-bottom: -10px;
    }
    30%{
        opacity: 0.5;
        margin-bottom: -10px;
    }
    85%{
        opacity: 1;
        margin-bottom: -10px;
    }
}    

.appu3 {
    font-family: expo-brand-bold,system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    color: #fff;
    font-weight: 400;
    font-size: 23px;
    line-height: 150%;
    margin-top: 10px;
    letter-spacing: -0.011em;
    animation: text2 2s 1;
}

@keyframes text2 {
    0%{
        opacity: 0;
    }
    30%{
        opacity: 0.5;
    }
    85%{
        opacity: 1;
    }
}

.temp {
    font-family: expo-brand-book,system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

