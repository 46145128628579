@import url(http://fonts.googleapis.com/css?family=Open%20Sans:300,400,500,600,700);
@import url(http://fonts.googleapis.com/css?family=Merriweather:300,400,500,600,700);


/* font-family: "Poppins", sans-serif;
font-family: "Open Sans", Arial, sans-serif;
font-family: "Merriweather", Arial, sans-serif; */

.home_space1{
    padding-top: 120px;
    margin: 30px;
}
.home_space7{
    padding-top: 70px;
    margin: 30px;
}

.about_heading {
    border-left: 5px solid rgb(1, 112, 202);
    padding-left: 20px;
    font-size: 35px;
    font-weight: bolder;
    color: rgb(2, 30, 53);
    font-weight: bold;
    font-family: 'Rajdhani', sans-serif;
    margin-top: 50px;
}

.about_box {
    height: 100%;
}

.home_acm_text{
    /* text-align: center; */
    padding: 10px;
    opacity: 0.9;
    font-size: 17px;
    line-height: 1.5;
    font-family: "Poppins", sans-serif;
    /* font-family: "Open Sans", Arial, sans-serif; */
    color: rgb(30, 30, 30);
}


.latest {
    margin-top: 100px;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 100px;
}

.card_deck {
    margin-left: 20px;
    margin-right: 20px;
}

.blog_card{
    margin: 30px;
    margin-left: 20px;
    background-color: transparent !important;
    border-bottom: 3px solid gray;
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
    min-height: 400px;
}

.card_title {
    font-size: 27px;
    font-family: "Poppins", Arial, sans-serif;
    /* font: 400 27px  Segoe UI,system-ui,-apple-system,sans-serif; */
}

.card_title2 {
    font-size: 13px;
    font-family: "Poppins", Arial, sans-serif;
    opacity: 0.7;
}

.card_text{
    padding-top: 18px;
    padding-bottom: 15px;
    font-weight: normal;
    font-size: 14px;
    font: 400 15px/1.7  Segoe UI,system-ui,-apple-system,sans-serif;
    font-family: "Poppins", Arial, sans-serif;
    line-height: 1.5;
}

.card_button{
    background-color: rgb(59, 107, 197);
    font-family: 'Rajdhani', sans-serif;
}

.initiatives_heading{
    font-size: 35px;
    font-weight: bolder;
    color: rgb(2, 30, 53);
    font-weight: bold;
    font-family: 'Rajdhani', sans-serif;
    font-family: Segoe UI,system-ui,-apple-system,sans-serif;
    font-family: "Poppins", Arial, sans-serif;
    text-align: center;
    padding-bottom: 20px;
}

.initiatives {
    text-align: center;
    font-size: 18px;
    font-family: "Poppins", Arial, sans-serif;
    font-weight: normal;
    opacity: 0.9;
    /* font: 400 17px/1.7  Segoe UI,system-ui,-apple-system,sans-serif; */
    color: rgb(30, 30, 30);
    line-height: 1.7;
    max-width: 800px;
    margin: 0 auto;
}
