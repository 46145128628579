@import url(http://fonts.googleapis.com/css?family=Open%20Sans:300,400,500,600,700);
@import url(http://fonts.googleapis.com/css?family=Merriweather:300,400,500,600,700);
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@700&display=swap');


/* Announcements */

.announce_card{
    margin-right: 10px;
    background-color: rgb(9, 49, 82);
}

.announce_card2{
    background-color: rgb(9, 49, 82);
}

.announce_card_heading {
    color: white;
    /* font-family: 'Barlow', sans-serif;
    font-family: Segoe UI,system-ui,-apple-system,sans-serif; */
    font-family: "Poppins", Arial, sans-serif;
    font-size: 27px;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 3px solid orange;
}

.announce_card_text {
    color: white;
    font-family: "Poppins", Arial, sans-serif;
    font-size: 15px;
}















